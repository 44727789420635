import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container-fluid overflow-hidden" }
const _hoisted_2 = {
  id: "app-container",
  class: "row overflow-auto"
}
const _hoisted_3 = {
  key: 1,
  style: {"display":"none"}
}
const _hoisted_4 = { class: "col-12 col-sm-11 app-content d-flex flex-column px-0 px-sm-2 mt-1 mt-sm-3 vh-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_notification = _resolveComponent("app-notification")!
  const _component_full_screen_loading_indicator = _resolveComponent("full-screen-loading-indicator")!
  const _component_app_layout_nav_sidebar = _resolveComponent("app-layout-nav-sidebar")!
  const _component_default_transition = _resolveComponent("default-transition")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_app_layout_content = _resolveComponent("app-layout-content")!
  const _component_app_layout_footer = _resolveComponent("app-layout-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_notification),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_full_screen_loading_indicator, { isLoading: false }),
      _createVNode(_component_default_transition, {
        name: _ctx.isAuthenticated ? 'slide-right' : 'slide-left'
      }, {
        content: _withCtx(() => [
          (_ctx.isAuthenticated)
            ? (_openBlock(), _createBlock(_component_app_layout_nav_sidebar, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_3))
        ]),
        _: 1
      }, 8, ["name"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_app_layout_content, null, {
          content: _withCtx(() => [
            _createVNode(_component_router_view, null, {
              default: _withCtx(({ Component }) => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_app_layout_footer)
      ])
    ])
  ]))
}