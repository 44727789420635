import { ProfileClubAPI } from "@/api/Profile-Club.api";
import { ProfileApi } from "@/api/Profile.api";
import { timeout } from "@/services/timeout.service";
import { ClubResponse } from "@/types/Club.Response";
import { NotificationTypes } from "@/types/NotificationTypes.enum";
import { ProfileResponse } from "@/types/Profile.Response";
import { UpdateProfileBody } from "@/types/UpdateProfile.Body";
import { UpdateProfilePasswordBody } from "@/types/UpdateProfilePassword.Body";
import { computed, ComputedRef, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useNotificationStore } from "./Notification.store";
import { ProfileState } from "./Profile.state";

const _profileAPI = new ProfileApi();
const _profileClubAPI = new ProfileClubAPI();

const state: ProfileState = reactive({} as ProfileState);

const me: ComputedRef<ProfileResponse> = computed(() => state.me);

const isAdmin: ComputedRef<boolean> = computed(
  () => state.me?.systemRole == "admin"
);

const clubsAreLoading: ComputedRef<boolean> = computed(
  () => state.clubsAreLoading
);

const clubs: ComputedRef<ClubResponse[]> = computed(() => state.clubs);

const optionsClubs: ComputedRef<{ value: string; label: string }[]> = computed(
  () =>
    (clubs.value || []).map((club: ClubResponse) => {
      return {
        value: club.id,
        label: `${club.name} (${club.countryCodeIOC})`,
      };
    })
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useProfileStore() {
  const { t } = useI18n();
  const { add } = useNotificationStore();

  const clearState = () => {
    state.me = {} as ProfileResponse;
    setTheme();
  };

  const load = () => {
    return _profileAPI
      .loadMe()
      .then(async (response) => {
        state.me = response;
        await loadClubs();
        setTheme();
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-reading-profile"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  const update = (me: UpdateProfileBody) => {
    return _profileAPI
      .updateMe(me)
      .then((response) => {
        state.me = response;
        setTheme();
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-updating-profile"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  const updatePassword = (me: UpdateProfilePasswordBody) => {
    return _profileAPI
      .updatePassword(me)
      .then((response) => {
        state.me = response;
        setTheme();
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-updating-password"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  const loadClubs = async () => {
    state.clubsAreLoading = true;
    return _profileClubAPI
      .loadClubs()
      .then((clubs) => {
        state.clubs = clubs;
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-reading-profile-clubs"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      })
      .finally(() => {
        timeout(() => (state.clubsAreLoading = false));
      });
  };

  const setTheme = () => {
    const documentElement = document.getElementsByTagName("body")[0];
    const classList = documentElement?.classList;
    if (classList) {
      classList.remove("theme--light-teal");
      classList.remove("theme--light-green");
      classList.remove("theme--dark-cyan");
    }
    if (me?.value?.theme) {
      classList?.add("theme--" + me.value.theme);
      localStorage.setItem("ijt_storage_theme", me.value.theme);
    } else {
      const localStorageTheme = localStorage.getItem("ijt_storage_theme");
      if (localStorageTheme) {
        classList?.add("theme--" + localStorageTheme);
      } else {
        classList?.add("theme--" + "light-teal");
      }
    }
  };

  return {
    me,
    isAdmin,
    loadClubs,
    clubsAreLoading,
    clubs,
    optionsClubs,
    clearState,
    load,
    update,
    updatePassword,
    setTheme,
  };
}
