export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export const alphabetical = (arr: any[], getter: any, order = "asc") =>
  arr.sort(
    order === "desc"
      ? (a: any, b: any) => getter(b).localeCompare(getter(a))
      : (a: any, b: any) => getter(a).localeCompare(getter(b))
  );
